<template>
<div class="wrapper">
  <aside class="side">
    <div class="sideTop">
      <!--<div style="background-color: #3D7EF7;border-radius: 20px;width: 35px;padding-left: 4px;padding-top: 2px;margin: 0 6px;">-->
      <!--  <img alt="" src="@/assets/image/application-one.png" width="30px">-->
      <!--</div>-->
      <p>采购关系</p>
    </div>
    <div class="sideSearch">
      <el-autocomplete   @select="handleSelect"   v-model="val1" class="inline-input searchInp" placeholder="请输入内容"  :fetch-suggestions="querySearch1"  clearable suffix-icon="el-icon-search">
        <template slot="prepend">主体1</template>
      </el-autocomplete>
      <el-button icon="el-icon-delete" circle style="margin-left: 6px;width: 40px"></el-button>
    </div>
    <div class="sideSearch">
      <el-autocomplete  @select="handleSelect"  placeholder="请输入内容"  v-model="val2" class="inline-input searchInp" :fetch-suggestions="querySearch2"   clearable suffix-icon="el-icon-search">
        <template slot="prepend">主体2</template>
      </el-autocomplete>
      <el-button icon="el-icon-delete" circle style="margin-left: 6px;width: 40px"></el-button>
    </div>
    <div class="sideSearch" v-for="(item, index) in addList" :key="index">
      <el-autocomplete  @select="handleSelect"  placeholder="请输入内容"  :v-model="item.value" class="inline-input searchInp" :fetch-suggestions="querySearch2"   clearable suffix-icon="el-icon-search">
        <template slot="prepend">主体{{ item.number }}</template>
      </el-autocomplete>
      <el-button icon="el-icon-delete" circle style="margin-left: 6px;width: 40px"></el-button>
    </div>
    <div class="sideBtnGroup">
      <el-button style="margin-right: 20px" @click="handleAddItems">+新增主体关系</el-button>
      <el-button type="primary">开始分析</el-button>
    </div>
    <div class="sideBody">
      <template v-if="showStatus===1">
      <div>关系列表1</div>
      <el-divider></el-divider>
      <div class="sideBodyContent">
        中石油（上海）新材料研究院有限公司
          <div class="describe">
            <div style="text-align: center;width: 100%;">招标代理</div>
            <img  src="@/assets/image/right.png" style="width: 70px;height: 10px; display: inline-block;transform: rotate(180deg)">
          </div>
        中石油物资采购公司
      </div>
      <br/>
      <br/>
      <div>关系列表2</div>
      <el-divider></el-divider>
      <div class="sideBodyContent">
        中石油（上海）新材料研究院有限公司
        <div class="describe">
          <div style="text-align: center;width: 100%;">持股100%</div>
          <img  src="@/assets/image/right.png" style="width: 70px;height: 10px; display: inline-block;transform: rotate(180deg)">
        </div>
        中国石油天然气股份有限公司
        <div class="describe">
          <div style="text-align: center;width: 100%;">持股100%</div>
          <img  src="@/assets/image/right.png" style="width: 70px;height: 10px; display: inline-block;">
        </div>
        中石油物资采购公司
      </div>
      </template>
      <template v-if="showStatus===2">
        <div>关系列表1</div>
        <el-divider></el-divider>
        <div class="sideBodyContent">
        中石油（上海）新材料研究院有限公司
          <div class="describe">
            <div style="text-align: center;width: 100%;">招标代理</div>
            <img  src="@/assets/image/right.png" style="width: 70px;height: 10px; display: inline-block;transform: rotate(180deg)">
          </div>
          中石油物资采购公司
          <div class="describe">
            <div style="text-align: center;width: 100%;">招标项目</div>
            <img  src="@/assets/image/right.png" style="width: 70px;height: 10px; display: inline-block;">
          </div>
          中石油（上海）新材料研究院有限公司超高效聚合物色谱仪
          <div class="describe">
            <div style="text-align: center;width: 100%;">购买产品</div>
            <img  src="@/assets/image/right.png" style="width: 70px;height: 10px; display: inline-block;">
          </div>
          超高效液相色谱仪
        </div>
      </template>
      <template v-if="showStatus===3">
        <div>关系列表1</div>
        <el-divider></el-divider>
        <div class="sideBodyContent">
          中石油（上海）新材料研究院有限公司
          <div class="describe">
            <div style="text-align: center;width: 100%;">招标代理</div>
            <img  src="@/assets/image/right.png" style="width: 70px;height: 10px; display: inline-block;transform: rotate(180deg)">
          </div>
          中石油物资采购公司
          <div class="describe">
            <div style="text-align: center;width: 100%;">招标项目</div>
            <img  src="@/assets/image/right.png" style="width: 70px;height: 10px; display: inline-block;">
          </div>
          中石油（上海）新材料研究院有限公司超高效聚合物色谱仪
          <div class="describe">
            <div style="text-align: center;width: 100%;">购买产品</div>
            <img  src="@/assets/image/right.png" style="width: 70px;height: 10px; display: inline-block;">
          </div>
          超高效聚合物色谱仪
          <div class="describe">
            <div style="text-align: center;width: 100%;">中标人</div>
            <img  src="@/assets/image/right.png" style="width: 70px;height: 10px; display: inline-block;transform: rotate(180deg)">
          </div>
          朗博国际有限公司
          <div class="describe">
            <div style="text-align: center;width: 100%;">制造商</div>
            <img  src="@/assets/image/right.png" style="width: 70px;height: 10px; display: inline-block;transform: rotate(180deg)">
          </div>
          Waters
        </div>
        <br/>
        <div>关系列表2</div>
        <el-divider></el-divider>
        <div class="sideBodyContent">
          中石油（上海）新材料研究院有限公司
          <div class="describe">
            <div style="text-align: center;width: 100%;">招标代理</div>
            <img  src="@/assets/image/right.png" style="width: 70px;height: 10px; display: inline-block;transform: rotate(180deg)">
          </div>
          中石油物资采购公司
          <div class="describe">
            <div style="text-align: center;width: 100%;">招标项目</div>
            <img  src="@/assets/image/right.png" style="width: 70px;height: 10px; display: inline-block;">
          </div>
          中石油（上海）新材料研究院有限公司超高效液相色谱仪
          <div class="describe">
            <div style="text-align: center;width: 100%;">购买产品</div>
            <img  src="@/assets/image/right.png" style="width: 70px;height: 10px; display: inline-block;">
          </div>
          超高效液相色谱仪
          <div class="describe">
            <div style="text-align: center;width: 100%;">中标人</div>
            <img  src="@/assets/image/right.png" style="width: 70px;height: 10px; display: inline-block;transform: rotate(180deg)">
          </div>
          新泰科（香港）有限公司
          <div class="describe">
            <div style="text-align: center;width: 100%;">制造商</div>
            <img  src="@/assets/image/right.png" style="width: 70px;height: 10px; display: inline-block;transform: rotate(180deg)">
          </div>
          Waters
        </div>
      </template>
    </div>
  </aside>
  <article class="articleBody">
    <div style="min-width: 1200px;">
      <stGraph :companies="companiesValue" :graphData="graphData"></stGraph>
    </div>
  </article>
</div>
</template>

<script>
import stGraph from "../graph_companyRelation/stGraph";
export default {
  name: "index",
  components: {stGraph},
  data(){
    return {
      companiesValue: [],
      graphData:{},
      graphData1: {
        "edges":[
          {
            "id": "000000000937",//链接招标机构和采购单位
            "relaType": "1",
            "source": "000000000002",
            "target": "000000000001"
          },
          {
            "id": "000000000939",// 连接股东单位和采购单位
            "positions": "股东100%持股",
            "relaType": "8",
            "source": "000000000002",
            "target": "000000000004"
          },
          {
            "id": "000000000940",// 连接股东单位和招标机构
            "positions": "股东100%持股",
            "relaType": "8",
            "source": "000000000001",
            "target": "000000000004"
          }
        ],
        "node":[{
          "baseFlag": true,
          "baseKey": "中国石油物资有限公司",//招标机构
          "id": "000000000001",
          "name": "中国石油物资有限公司",
          "nodeType": "2",
          "status": "注销",
          "targetKey": []
        },
          {
            "baseFlag": true,
            "baseKey": "中石油（上海）新材料研究院有限公司",//采购单位
            "id": "000000000002",
            "name": "中石油（上海）新材料研究院有限公司",
            "nodeType": "1",
            "status": "注销",
            "targetKey": []
          },

          {
            "baseFlag": true,//股东单位
            "baseKey": "中国石油天然气股份有限公司",
            "id": "000000000004",
            "name": "中国石油天然气股份有限公司",
            "nodeType": "7",
            "status": "注销",
            "targetKey": []
          },
        ]
      },
      graphData2:{
        "node": [
          {
            "baseFlag": true,  // 不知道先照着抄
            "baseKey": "中石油（上海）新材料研究院有限公司超高效液相色谱仪",  // 不知道先与名字一致
            "id": "000000000000",  //自身唯一id
            "name": "中石油（上海）新材料研究院有限公司超高效液相色谱仪", //圈里的名字
            "nodeType": "6", // 对应圈的类型
            "status": "存续", // 不知道先照着抄 目前看有存续和注销俩种状态
            "targetKey": []  // 不知道先照着抄
          },
          {
            "baseFlag": true,
            "baseKey": "中国石油物资有限公司",//招标机构
            "id": "000000000001",
            "name": "中国石油物资有限公司",
            "nodeType": "2",
            "status": "注销",
            "targetKey": []
          },
          {
            "baseFlag": true,
            "baseKey": "中石油（上海）新材料研究院有限公司",//采购单位
            "id": "000000000002",
            "name": "中石油（上海）新材料研究院有限公司",
            "nodeType": "1",
            "status": "注销",
            "targetKey": []
          },
          {
            "baseFlag": true,//采购产品
            "baseKey": "超高效液相色谱仪",
            "id": "000000000011",
            "name": "超高效液相色谱仪",
            "nodeType": "5",
            "status": "注销",
            "targetKey": []
          },
        ],
        "edges": [
          {
            "id": "000000000936",   //自身唯一id
            "relaType": "2", //线对应的关系类型
            "source": "000000000001",  // 源头对应的圈id
            "target": "000000000000"  // 目标对应的圈id
          },
          {
            "id": "000000000937",
            "relaType": "1",
            "source": "000000000002",
            "target": "000000000001"
          },
          {
            "id": "000000000947",// 连接采购产品和项目
            "positions": "采购产品",
            "relaType": "5",
            "source": "000000000011",
            "target": "000000000000"
          },
        ]
      },
      graphData3:{
        "node": [

          {
            "baseFlag": true,
            "baseKey": "中石油（上海）新材料研究院有限公司",//招标机构
            "id": "000000000001",
            "name": "中石油（上海）新材料研究院有限公司",
            "nodeType": "1",
            "status": "注销",
            "targetKey": []
          },
          {
            "baseFlag": true,
            "baseKey": "中国石油物资有限公司",//采购单位
            "id": "000000000002",
            "name": "中国石油物资有限公司",
            "nodeType": "2",
            "status": "注销",
            "targetKey": []
          },

          {
            "baseFlag": true,  // 不知道先照着抄
            "baseKey": "中石油（上海）新材料研究院有限公司超高效聚合物色谱仪",  // 不知道先与名字一致
            "id": "000000000003",  //自身唯一id
            "name": "中石油（上海）新材料研究院有限公司超高效聚合物色谱仪", //圈里的名字
            "nodeType": "6", // 对应圈的类型
            "status": "存续", // 不知道先照着抄 目前看有存续和注销俩种状态
            "targetKey": []  // 不知道先照着抄
          },
          {
            "baseFlag": true,  // 不知道先照着抄
            "baseKey": "中石油（上海）新材料研究院有限公司超高效液相色谱仪",  // 不知道先与名字一致
            "id": "000000000004",  //自身唯一id
            "name": "中石油（上海）新材料研究院有限公司超高效液相色谱仪", //圈里的名字
            "nodeType": "6", // 对应圈的类型
            "status": "存续", // 不知道先照着抄 目前看有存续和注销俩种状态
            "targetKey": []  // 不知道先照着抄
          },
          {
            "baseFlag": true,//采购产品
            "baseKey": "超高效聚合物色谱仪",
            "id": "000000000005",
            "name": "超高效聚合物色谱仪",
            "nodeType": "5",
            "status": "注销",
            "targetKey": []
          },
          {
            "baseFlag": true,//中标人
            "baseKey": "朗博国际有限公司",
            "id": "000000000006",
            "name": "朗博国际有限公司",
            "nodeType": "3",
            "status": "注销",
            "targetKey": []
          },
          {
            "baseFlag": true,//采购产品
            "baseKey": "超高效液相色谱仪",
            "id": "000000000007",
            "name": "超高效液相色谱仪",
            "nodeType": "5",
            "status": "注销",
            "targetKey": []
          },
          {
            "baseFlag": true,//液相的中标人
            "baseKey": "新泰科（香港）有限公司",
            "id": "000000000008",
            "name": "新泰科（香港）有限公司",
            "nodeType": "3",
            "status": "注销",
            "targetKey": []
          },
          {
            "baseFlag": true,//中标人的制造商
            "baseKey": "Waters （美国）",
            "id": "000000000009",
            "name": "Waters （美国）",
            "nodeType": "9",
            "status": "注销",
            "targetKey": []
          },
        ],
        "edges": [
          {
            "id": "000000000937",//链接采购单位和招标机构
            "relaType": "1",
            "source": "000000000001",
            "target": "000000000002"
          },
          {
            "id": "000000000938",// 连接招标机构和聚合物项目
            "positions": "股东100%持股",
            "relaType": "2",
            "source": "000000000002",
            "target": "000000000003"
          },
          {
            "id": "000000000939",// 连接招标机构和液项项目
            "positions": "股东100%持股",
            "relaType": "2",
            "source": "000000000002",
            "target": "000000000004"
          },

          {
            "id": "000000000940",// 连接采购产品聚合物和项目
            "positions": "采购产品",
            "relaType": "5",
            "source": "000000000005",//产品
            "target": "000000000003"
          },
          {
            "id": "000000000941",// 连接采购产品液相和项目
            "positions": "采购产品",
            "relaType": "5",
            "source": "000000000007",//产品
            "target": "000000000004"
          },

          {
            "id": "000000000942",   //聚合物中标人和产品
            "relaType": "7", //线对应的关系类型
            "source": "000000000006",  // 源头对应的圈id
            "target": "000000000005"  // 目标对应的圈id
          },

          {
            "id": "000000000943",   //液相中标人和产品
            "relaType": "7", //线对应的关系类型
            "source": "000000000008",  // 源头对应的圈id
            "target": "000000000007"  // 目标对应的圈id
          },
          {
            "id": "000000000944",// 连接制造商和中标人
            "positions": "制造商",
            "relaType": "9",
            "source": "000000000009",
            "target": "000000000006"
          },
          {
            "id": "000000000945",// 连接制造商和中标人
            "positions": "制造商",
            "relaType": "9",
            "source": "000000000009",
            "target": "000000000008"
          },
        ]
      },
      val1:'中石油（上海）新材料研究院有限公司',
      val2:'Waters',
      company1:[
        { "value": "中石油（上海）新材料研究院有限公司",key:1},
      ],
      company2:[
        { "value": "中石油物资有限公司",key:2},
        { "value": "超高效液相色谱仪",key:3},
        { "value": "Waters",key:4},
      ],
      status:false,
      showStatus:3,
      addList: []
    }
  },
  created() {
    this.graphData = this.graphData3
  },
  methods:{
    handleSelect(){
      if (this.val1&& this.val2) {
        if(this.val2==='超高效液相色谱仪'){
          this.showStatus = 2
          this.graphData =this.graphData2
        }
        else if(this.val2==='中石油物资有限公司'){
          this.showStatus = 1
          this.graphData =this.graphData1
        }
        else if(this.val2==='Waters'){
          this.showStatus = 3
          this.graphData =this.graphData3
        }
      }
    },
    querySearch1(queryString, cb) {
      var company1 = this.company1;
      var results = queryString ? company1.filter(this.createFilter(queryString)) : company1;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    querySearch2(queryString, cb) {
      var company2 = this.company2;
      var results = queryString ? company2.filter(this.createFilter(queryString)) : company2;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleAddItems() {
      this.addList.push({
        value: 'val' + Number(this.addList.length + 3),
        number: this.addList.length + 3
      })
    }

  }
}
</script>

<style scoped lang="scss">
.wrapper{
  height: 100vh;
  background-image: url("~@/assets/image/home_bg.jpg");
  display: flex;
  .side {
    width: 400px;
    box-shadow: 8px 8px 20px rgba(56, 75, 102, 0.1);

    .sideTop {
      display: flex;
      align-items: center;
      border-radius: 40px;
      margin: 20px 20px;
      padding: 8px;
      background-color: #e3e0ec;
      height: 32px;
      p{
        font-weight: bold;
        font-size: 18px;
        margin-left: 22px;
      }
    }
    .sideTitle{
      display: flex;
      align-items: center;
      height: 32px;
      margin: 40px 20px 20px 25px;
      font-size: 16px;
      font-weight: 500;
      img{
        padding: 16px;
      }
    }
    .sideSearch{
      display: flex;
      margin: 20px 20px;
      ::v-deep .el-input__inner{
        width: 250px;
      //  border-radius:20px;
      //  height: 40px;
      //  border: 1px solid #dcdfe6;
      //  color: #606266;
      //  padding: 0 15px;
      //  background-color: #fff;
      }
    }
    .sideBtnGroup{
      margin: 0 auto;
      text-align: center;
    }
    .sideBody{
      margin-top: 10px;
      padding: 20px;
      .el-divider--horizontal {
        margin:12px 0;
        height: 2px;
        color:red;
      }
      .el-divider {
        background-color: #ebebef;
        position: relative;
      }
      .sideBodyContent{
        //display: flex;
        line-height: 16px;
        flex-wrap:wrap;
        font-size: 14px;
        background-color: #fcfbfb;
        border-radius: 8px;
        padding: 8px;
        color: #737373;
        //.contentItem{
        //  display: flex;
        //  padding: 8px;
          .describe{
            color: #606266;
            margin: 2px 5px 0 5px;
            font-size: 10px;
            display: inline-block;
            //display: flex;
            //flex-direction: column;
            //align-items: flex-end;
          }
        //}
      }
      //ul{
      //  list-style: none;
      //  li{
      //    cursor: pointer;
      //    line-height: 32px;
      //    font-size: 16px;
      //    font-weight: 500;
      //    margin-top:50px;
      //    color:#555
      //  }
      //}
    }
  }
  .articleBody {
    display: flex;
    flex: 1;
    //flex-direction: column;
    //align-items: flex-end;
    //justify-content:space-around;
    padding:30px 40px;
    .articleWrapper{
      height: 60%;
      background-color: #fff;
      //border-radius: 10px;
      //border: 2px solid #FFF;
    }

    //width: 100%;
    .emptyBodyDescribe{
      color: #f4990b;
      font-weight: bold;
      font-size: 20px;
      margin-top: 40px;
      width: 100%;
    }
    .emptyBodyCard{
      overflow: auto;
      margin-top: 60px;
      display: flex;
      flex-wrap:wrap;
      padding: 24px;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      border-radius: 10px;
      border: 2px solid #FFF;
      background: rgba(255, 255, 255, 0.40);
      .cardTitle{
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
      }
      .cardBody{
        height: 120px;
        margin: 16px 0 8px 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
      }
    }
    .answerCard{
      margin-top: 40px;
      //display: flex;
      //flex-wrap:wrap;
      padding: 24px;
      //align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      border-radius: 10px;
      border: 2px solid #9ca3f5;
      background: rgba(255, 255, 255, 0.40);
      //position: absolute;
      //bottom:50px;
      //left: 400px;
      .answerCardTitle{
        color: #999;
        font-size: 14px;
        margin-bottom: 10px;
        span{
          cursor: pointer;
        }
      }
      .answerCardTxt{
        border-radius: 4px;
      }
    }
  }
  table {
    border-collapse: separate;
    border-top: 1px solid #eaedf4;
    border-left: 1px solid #eaedf4;
  }
  tr th {
    color: #8f9298;
    background-color: #f5f5f5;
    padding: 6px;
    text-align: center;
    border-bottom: 1px solid #eaedf4;
    border-right: 1px solid #eaedf4;
    height: 34px;
  }
  tr td {
    font-size: 14px;
    color: #606266;
    background-color: #fff;
    padding: 6px;
    text-align: center;
    height: 34px;//设置单元格最小高度
    border-bottom: 1px solid #eaedf4;
    border-right: 1px solid #eaedf4;
  }
}




.normal_right_arrow {
  display: inline-block;
  width: 40px;
  height: 2px;
  box-shadow: 0px 0px 0px 25px #ff0000 inset;
  background-color: #ff0000;
}
.normal_right_arrow::after {
  content: "";
  display: inline-block;
  position: relative;
  left: 40px;
  border-left: 5px solid #ff0000;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}



.right_arrow {
  font-size: 12px;
  border-bottom: 2px solid red;
  margin-bottom: 45px;
  //display: inline-block;
  //width: 40px;
  //height: 2px;
  //box-shadow: 0px 0px 0px 25px #ff0000 inset;
  //background-color: #ff0000;
}
.right_arrow::after {
  content: "";
  display: inline-block;
  position: relative;
  left: 40px;
  border-left: 5px solid #ff0000;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}
</style>
